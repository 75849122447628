import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { Row, Col } from "react-styled-flexboxgrid";
import { Button } from "../../components/Button";
import { Primary } from "../../components/Section";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-tropical-sunset.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 75%;
`;
const Label = styled.label`
  text-align: left;
  display: block;
  margin-bottom: 0;
`;
const Input = styled.input`
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out,
    -webkit-box-shadow 0.5s;
`;
const TextArea = styled.textarea`
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out,
    -webkit-box-shadow 0.5s;
`;

const ReachUs = ({ data }) => {
  return (
    <section>
      <StyledBackgroundSection centered>
        <Row>
          <Col xs={12}>
            <h1>Reach Us</h1>
            <p>
              Create history with us! We look forward to hearing from you. If
              you are interested in working with any of our companies please do
              not hesitate to contact us. Touchless Tableware, Hand Surgery,
              Mediation, Fashion and even Horse ownership is at your fingertips.
            </p>
          </Col>
        </Row>
      </StyledBackgroundSection>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <form
              name="contact"
              method="post"
              action="/thanks"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Label>Your Name:* </Label>
              <Input type="text" name="name" id="name" required />
              <Label>Your Email:* </Label>
              <Input type="email" name="email" id="email" required />
              <Label>Your Phone: </Label>
              <Input type="phone" name="phone" id="phone" />
              <Label>Message:* </Label>
              <TextArea name="message" id="message" rows="4" cols="50" />
              <Button type="submit" required>
                Send your message
              </Button>
            </form>
          </Col>
          <Col xs={12} md={6}>
            <Img
              fluid={data.digitalhands.childImageSharp.fluid}
              alt="Paris Skyline"
            />
          </Col>
        </Row>
      </Primary>
    </section>
  );
};

export default ReachUs;
