import { css } from "styled-components";

export const styleBackgroundImage = css`
  width: 100%;
  padding: 5rem 1rem;

  text-align: ${(props) => (props.centered ? "center" : "initial")};
  min-height: 450px;
  color: white;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
  h1 {
    font-size: 58px;
    font-weight: 700;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
  }
  strong {
    color: ${(props) => props.theme.greenApple};
  }
`;
