import React from "react";
import Layout from "../components/Layout";

import ReachUs from "../components/ReachUs";

const ReachUsPage = ({ data }) => (
  <Layout>
    <ReachUs data={data} />
  </Layout>
);

export default ReachUsPage;

export const query = graphql`
  query {
    digitalhands: file(relativePath: { eq: "mj-digital-hands.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
